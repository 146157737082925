@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900&display=swap');

body,
html {
  padding: 0;
  margin: 0;
}

.App {
  background: #1d1d1d;
}

.card-canvas {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  position: relative;
}

.matter-canvas {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
}

.btns {
  position: absolute;
  z-index: 21;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.btn-grad {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-image: linear-gradient(to right, #ff6e7f 0%, #bfe9ff 51%, #ff6e7f 100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
  font-weight: 900;
  font-family: Roboto;
  margin: 10px auto;

  width: 230px;
  /* left: calc(50% - 115px);
  position: absolute;
  z-index: 21; */
}

.btn-grad:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
