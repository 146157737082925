@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Druk Text';
  src: url('./fonts/druktext-bold-webfont.woff2') format('woff2'),
    url('./fonts/druktext-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Text';
  src: url('./fonts/druktext-bolditalic-webfont.woff2') format('woff2'),
    url('./fonts/druktext-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Druk Text';
  src: url('./fonts/druktext-heavy-webfont.woff2') format('woff2'),
    url('./fonts/druktext-heavy-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Text';
  src: url('./fonts/druktext-heavyitalic-webfont.woff2') format('woff2'),
    url('./fonts/druktext-heavyitalic-webfont.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Druk Text';
  src: url('./fonts/druktext-medium-webfont.woff2') format('woff2'),
    url('./fonts/druktext-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Text';
  src: url('./fonts/druktext-mediumitalic-webfont.woff2') format('woff2'),
    url('./fonts/druktext-mediumitalic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Druk Text';
  src: url('./fonts/druktext-super-webfont.woff2') format('woff2'),
    url('./fonts/druktext-super-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Text';
  src: url('./fonts/druktext-superitalic-webfont.woff2') format('woff2'),
    url('./fonts/druktext-superitalic-webfont.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'druk_text_widebold';
  src: url('./fonts/druktextwide-bold-webfont.woff2') format('woff2'), url('./fonts/druktextwide-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'druk_text_widebold_italic';
  src: url('./fonts/druktextwide-bolditalic-webfont.woff2') format('woff2'),
    url('./fonts/druktextwide-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'druk_text_wideheavy';
  src: url('./fonts/druktextwide-heavy-webfont.woff2') format('woff2'), url('./fonts/druktextwide-heavy-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'druk_text_wideheavy_italic';
  src: url('./fonts/druktextwide-heavyitalic-webfont.woff2') format('woff2'),
    url('./fonts/druktextwide-heavyitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Druk Text Wide';
  src: url('./fonts/druktextwide-medium-webfont.woff2') format('woff2'), url('./fonts/druktextwide-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'druk_text_widemedium_italic';
  src: url('./fonts/druktextwide-mediumitalic-webfont.woff2') format('woff2'),
    url('./fonts/druktextwide-mediumitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'druk_text_widesuper';
  src: url('./fonts/druktextwide-super-webfont.woff2') format('woff2'), url('./fonts/druktextwide-super-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'druk_text_widesuper_italic';
  src: url('./fonts/druktextwide-superitalic-webfont.woff2') format('woff2'),
    url('./fonts/druktextwide-superitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

:root {
  /* --play-mode-color: linear-gradient(-250deg, #0270d1 0%, #5ce2b1 100%);
  --edit-mode-color: linear-gradient(250deg, #9d6cff, #f55a50); */
  --play-mode-color: #9d6cff;
  --edit-mode-color: #199e66;
  --dark-grey: rgba(218, 218, 218, 0.2);
  --swiper-navigation-color: #fff;
  --swiper-pagination-color: #fff;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-size: 12px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
